import React, { useEffect, useState } from "react";
import axios from "axios";
import "./App.css";

import { ThemeOptions } from "./ideComponents/CodeEditorComponents/ThemeOptions";
import getInitialCodes from "./ideComponents/NavbarComponents/InitialCodes";
import CodeEditor from "./ideComponents/CodeEditor";
import IdeHeader from "./ideComponents/IdeHeader";
import InputWindow from "./ideComponents/InputWindow";
import OutputWindow from "./ideComponents/OutputWindow";
import EditorHeader from "./ideComponents/CodeEditorComponents/EditorHeader";

import SettingsPopup from "./ideComponents/CodeEditorComponents/SettingsPopup"

//resizable module
import SplitPane, { Pane, SashContent } from 'split-pane-react';
import 'split-pane-react/esm/themes/default.css'
//resizable icons:
import MoreVertIcon from '@mui/icons-material/MoreVert';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';

function IdeHomepage({ routeLang }) {

    //Triggers settings pop up:
    const [showSettings, setShowSettings] = useState(false);

    //controls clickability of the Run button:
    const [click, setClick] = useState(true);

    //Settings menu variables:
    const [language, setLanguage] = useState("cpp");
    const [theme, setTheme] = useState(ThemeOptions[0]);
    const [fontFamily, setFontFamily] = useState("Ubuntu Mono");
    const [fontSize, setFontSize] = useState("16px");

    //resizable variables:
    const [sizes, setSizes] = useState([
        '66%',
        '34%',
    ]);
    const [horizSizes, setHorizSizes] = useState([
        '100%',
        '100%',
    ]);

    //Initial cpp code to be seen in the editor.
    const initialCodeCPP = `#include<bits/stdc++.h>
using namespace std;

int main(){
    //Write your code here. 
    cout << "Hello World!";
    return 0;
}`

    //Main editor section variables: souece_code, input and output.
    const [code, setCode] = useState(initialCodeCPP);
    const [customInput, setCustomInput] = useState("");
    const [outputDetails, setOutputDetails] = useState(null);

    //change language with different router:
    useEffect(function () {
        setLanguage(routeLang);
        setCode(getInitialCodes(routeLang));
    }, [routeLang]);

    //Different screen width
    const [screenWidth, setScreenWidth] = useState(window.innerWidth);
    useEffect(() => {
        window.addEventListener("resize", () => setScreenWidth(window.innerWidth));
    }, []);

    // This function is POST ing data to the backend and receiving 
    // the result. It is trigerred from the RUN button.

    const handleCompile = () => {
        setClick(false);
        const formData = {
            language: language,
            code: code,
            input: customInput,
        };

        axios.post(`${process.env.REACT_APP_API_URL}submitCode`, formData)
            .then(response => {
                const data = response.data;
                const result = data.results;
                let text = "";

                if (data.success) {
                    if (result.passed) {
                        text = "Code executed successfully:\n\n";
                        text += `Output: ${result.actualOutput}`;
                    } else {
                        text = `${result.description}:\n\n`;

                        if (result.description === "Compilation Error") {
                            text += result.compile_output;
                        } else if (result.description === "Runtime Error (NZEC)") {
                            text += result.stderr;
                        } else if (result.description === "Wrong Answer") {
                            text += result.actualOutput;
                        }
                    }
                    text += `\n\nTime Taken: ${result.time} seconds`;
                    setOutputDetails(text);
                } else {
                    setOutputDetails("Error in code submission.");
                }

                setClick(true);
            })
            .catch((error) => {
                console.error('Error:', error);
                setOutputDetails("Error submitting code.");
                setClick(true);
            });
    };

    return (
        <>
            {showSettings && <SettingsPopup setShowSettings={setShowSettings}
                theme={theme} setTheme={setTheme} ThemeOptions={ThemeOptions}
                fontFamily={fontFamily} setFontFamily={setFontFamily}
                fontSize={fontSize} setFontSize={setFontSize}
            />}

            <div className="container-fluid">
                <div className="header-panel" style={{ paddingLeft: "0", paddingRight: "0" }}>

                    <IdeHeader
                        handleCompile={handleCompile} language={language} setLanguage={setLanguage}
                        code={code} setCode={setCode} click={click}
                    />

                </div>

            {screenWidth > 999 && <div className="editor">
                                <SplitPane
                                    className="row"
                                    split='vertical'
                                    sizes={sizes}
                                    onChange={setSizes}
                                    resizerSize={6}
                                    sashRender={(index, active) => (
                                        <SashContent className="action-sash-wrap">
                                            <MoreVertIcon sx={{ color: "#fff" }} />
                                        </SashContent>
                                    )}
                                >
                                    <Pane minSize="30%" maxSize='75%'>
                                        <EditorHeader language={language} setCode={setCode} code={code}
                                            setShowSettings={setShowSettings} click={click}
                                            handleCompile={handleCompile} />
                                        <CodeEditor code={code} language={language}
                                            theme={theme} setCode={setCode}
                                            fontFamily={fontFamily} fontSize={fontSize}
                                        />
                                    </Pane>
                                    <div className="pane-second" style={{ paddingLeft: "3px", paddingRight: "0" }}>
                                        <SplitPane
                                            split='horizontal'
                                            sizes={horizSizes}
                                            onChange={setHorizSizes}
                                            resizerSize={6}
                                            sashRender={(index, active) => (
                                                <SashContent className="action-sash-wrap">
                                                    <MoreHorizIcon sx={{ color: "#fff" }} />
                                                </SashContent>
                                            )}
                                        >
                                            <Pane minSize="20%" maxSize='80%'>
                                                <InputWindow customInput={customInput} setCustomInput={setCustomInput}
                                                    fontSize={fontSize} fontFamily={fontFamily}
                                                />
                                            </Pane>
                                            <OutputWindow outputDetails={outputDetails} setOutputDetails={setOutputDetails}
                                                fontSize={fontSize} fontFamily={fontFamily}
                                            />
                                        </SplitPane>
                                    </div>
                                </SplitPane>
                            </div >}
                            {screenWidth < 1000 &&
                                <div className="row">
                                    <div className="col-md-8 main-editor">
                                        <EditorHeader language={language} setCode={setCode} code={code}
                                            setShowSettings={setShowSettings} click={click}
                                            handleCompile={handleCompile} />
                                        <CodeEditor code={code} language={language}
                                            theme={theme} setCode={setCode}
                                            fontFamily={fontFamily} fontSize={fontSize}
                                        />
                                    </div>
                                    <div className="col-md-4" style={{ paddingLeft: "0", paddingRight: "0" }}>
                                        <InputWindow customInput={customInput} setCustomInput={setCustomInput}
                                            fontSize={fontSize} fontFamily={fontFamily}
                                        />
                                        <OutputWindow outputDetails={outputDetails} setOutputDetails={setOutputDetails}
                                            fontSize={fontSize} fontFamily={fontFamily}
                                        />
                                    </div>
                                </div >
                    }
             </div > 
        </>
    );
}

export default IdeHomepage;