import React from "react";
import './App.css';
import { Routes, Route } from "react-router-dom";

import IdeHomepage from './ideHomepage';

function App() {

  return (
    <Routes>
      <Route exact path="/" element={<IdeHomepage routeLang="cpp" />} />
      <Route exact path="/code/:userId" element={<IdeHomepage routeLang="cpp" />} />
      <Route exact path="/online-cpp-compiler" element={<IdeHomepage routeLang="cpp" />} />
      <Route exact path="/online-c-compiler" element={<IdeHomepage routeLang="c" />} />
      <Route exact path="/online-java-compiler" element={<IdeHomepage routeLang="java" />} />
      <Route exact path="/online-python-compiler" element={<IdeHomepage routeLang="python" />} />
      <Route exact path="/online-javascript-compiler" element={<IdeHomepage routeLang="javascript" />} />
    </Routes>
  );
}

export default App;
