import React, { useState, useEffect, useRef } from "react";
import getFileName from "./getFileName";

//icons:
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import NoteAddOutlinedIcon from '@mui/icons-material/NoteAddOutlined';
// import FileUploadOutlinedIcon from '@mui/icons-material/FileUploadOutlined';
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';

export default function FileDropdown({ code, setCode, language }) {
    const [open, setOpen] = useState(false);

    //download code as file:
    const codeDownload = code;
    // const element = document.getElementById("code-download-btn");
    const codeFile = new Blob([codeDownload], { type: "text/plain" });


    // Code to close dropdown on outside clicking of mouse:
    let menuRef = useRef();
    useEffect(() => {
        let handler = (e) => {
            if (menuRef.current && !menuRef.current.contains(e.target)) {
                setOpen(false);
            }
        };
        document.addEventListener("mousedown", handler);
        document.addEventListener('touchstart', handler);
        return () => {
            document.removeEventListener("mousedown", handler);
            document.removeEventListener('touchstart', handler);
        }
    });

    //upload code file (Disabled for now):
    // const showFile = (e) => {
    //     if (window.File && window.FileReader && window.FileList && window.Blob) {
    //         var file = e.target.files[0];
    //         var reader = new FileReader()

    //         reader.onload = function (event) {
    //             setCode(event.target.result);
    //         }
    //         reader.readAsText(file);

    //     } else {
    //         alert("Your browser is too old to support HTML5 File API");
    //     }
    // }

    //To close dropdown menu of File
    function handleClick(e) {
        setOpen(false);
    }

    return (<>
        <div className="dropdown-menu-container">
            <div className="dropdown-trigger"
                style={{ justifyContent: "flex-start", paddingLeft: "10px" }}>
                <label ref={menuRef} style={{ margin: "0", cursor: "pointer" }} onClick={() => { setOpen(!open) }}>
                    <span>File</span>
                    <ArrowDropDownIcon sx={{ fontSize: "20px" }} />
                </label>
            </div>

            <div className={`dropdown-menu-items ${open ? "active" : "inactive"}`}>

                {/* ---------------------------------New File option---------------------------------- */}
                <a href="https://ide.takeuforward.org/" target="_blank" 
                rel="noreferrer" style={{all: "unset"}}>
                <div className="custom-dropdown-item" onClick={handleClick} style={{ padding: "5px 5px 5px 10px" }}>
                    <a id="file-btn" href="https://ide.takeuforward.org/" target="_blank" rel="noreferrer"
                        data-toggle="tooltip" data-placement="right"
                        title="Open new IDE in a new tab."
                    >
                        <span style={{ paddingRight: "5px" }}><NoteAddOutlinedIcon sx={{ fontSize: "1.25rem" }} /></span>
                        <span>New</span>
                    </a>
                </div>
                </a>

                {/* ----------------------------------Upload File option (Disablesd)-------------------- */}
                {/* <div className="dropdown-item" style={{ paddingLeft: "15px" }} onClick={handleClick}>
                    <label style={{ cursor: "pointer" }}>
                        <span style={{ paddingRight: "5px" }}><FileUploadOutlinedIcon sx={{ fontSize: "1.25rem" }} /></span>
                        <span>Upload</span>
                        <input type="file" style={{ display: "none" }}
                            onChange={showFile}
                        />
                    </label>
                </div> */}

                {/* ---------------------------------Download option---------------------------------- */}
                <a download={getFileName(language)}
                    href={URL.createObjectURL(codeFile)} style={{all: "unset"}}>
                <div className="custom-dropdown-item" value="3" onClick={handleClick} style={{ padding: "5px 5px 5px 10px" }}>
                    <a id="file-btn" download={getFileName(language)}
                        href={URL.createObjectURL(codeFile)}
                        data-toggle="tooltip" data-placement="right"
                        title="Download the source code."
                    >
                        <span style={{ paddingRight: "5px" }}><FileDownloadOutlinedIcon sx={{ fontSize: "1.25rem" }} /></span>
                        <span>Download</span>
                    </a>
                </div>
                </a>
            </div>
        </div >
    </>);
}
