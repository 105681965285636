import { useState } from "react";
import toast, { Toaster } from 'react-hot-toast';
import getInitialCodes from "../NavbarComponents/InitialCodes";

//icons:
import RefreshRoundedIcon from '@mui/icons-material/RefreshRounded';
import SettingsRoundedIcon from '@mui/icons-material/SettingsRounded';
// import ShareRoundedIcon from '@mui/icons-material/ShareRounded';
import ClearRoundedIcon from '@mui/icons-material/ClearRounded';
import LinkRoundedIcon from '@mui/icons-material/LinkRounded';
import ContentCopyRoundedIcon from '@mui/icons-material/ContentCopyRounded';
// import PlayCircleFilledRoundedIcon from '@mui/icons-material/PlayCircleFilledRounded';

import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

export default function EditorHeader({ language, setCode, code, setShowSettings,
    click, shareId, setShareId, handleCompile }) {
    // eslint-disable-next-line 
    const [isClicked, setIsClicked] = useState(false);
    const [isOpen, setIsOpen] = useState(false);
    const [dialogOpen, setDialogOpen] = useState(false);
    // const saveCode = ()=>{
    //     setIsClicked(true);
    //     const formData = {
    //         "language": language,
    //         "code": code,
    //     }
    //     //"https://ideapi.takeuforward.org/saveCode"
    //     const url = process.env.REACT_APP_API_URL+"saveCode";
    //     fetch(url,
    //         {
    //             method: 'POST', // or 'PUT'
    //             headers: { 'Content-Type': 'application/json', },
    //             body: JSON.stringify(formData),
    //         })
    //         .then(response => response.json())
    //         .then(data => {
    //             //console.log('Success:', data);
    //             let result = null;
    //             if(data.success === true) result = data.id;
    //             setShareId(result);
    //             setIsOpen(true);
    //             // console.log(result);
    //         })
    //         .catch((error) => {
    //             console.error('Error:', error);
    //             setIsClicked(false);
    //         });
    // }

    const showToastMessage = (msg) => {
        toast.success(msg, {
            position: "top-right",
        });
    }

    const handleDialogOpen = () => {
        setDialogOpen(true);
    };

    const handleDialogClose = () => {
        setDialogOpen(false);
    };

    const handleConfirmReset = () => {
        setCode(getInitialCodes(language));
        handleDialogClose();
    };

    return (
        <>
            <div className="panel-heading" style={{
                // borderRight: "1.5px solid rgb(70,70,70)", 
                width: "calc(100% - 3px)"
            }}>
                <span className="panel-heading-text">Code</span>


                {/* -----------------------------------------RUN Button---------------------------------- */}

                {/* ----------------------------------------------------Run button section-------- */}
                {/* <div className={`run-btn ${click ? "" : "not-clickable"}`} onClick={handleCompile}>
                <label style={{ margin: "0", cursor: "pointer" }}>
                    <PlayCircleFilledRoundedIcon sx={{ fontSize: "20px" }} />
                    <span>{click ? "RUN" : "RUNNING"}</span>
                </label>
            </div> */}


                {/* -----------------------------------------Reset Source code------------------------------------ */}
                <div className="panel-first-icon">
                    <span className="panel-heading-icon">
                        <RefreshRoundedIcon sx={{ fontSize: 20, ":hover": { color: "red" } }}
                            onClick={handleDialogOpen} />
                    </span>
                </div>

                {/* ------------------------------------Editor settings section----------------------------------------------- */}
                <div className="panel-second-icon">
                    <span className="panel-heading-icon">
                        <SettingsRoundedIcon sx={{ fontSize: 20, ":hover": { color: "red" } }}
                            onClick={() => { setShowSettings(true) }}
                        />
                    </span>
                </div>

                {/* -------------------------------------Share source code section-------------------------------- */}
                {/* <div className={`panel-third-icon`}>
                <span className={`panel-heading-icon ${isClicked? "not-clickable" : ""}`} onClick={() => copyToClipboard(code)}>
                    <ShareRoundedIcon sx={{ fontSize: 20, ":hover": { color: "red" } }} />
                </span>
            </div> */}

                {/* -------------------------------------Copy source code section-------------------------------- */}
                <div className="panel-second-icon">
                    <Toaster />
                    <span className="panel-heading-icon">
                        <ContentCopyRoundedIcon sx={{ fontSize: 20, ":hover": { color: "red" } }}
                            onClick={() => {
                                navigator.clipboard.writeText(code);
                                code && showToastMessage("Code copied successfully!")
                            }}
                        />
                    </span>
                </div>
            </div>

            <div className={`${isOpen ? "share-link-window-active" : "share-link-window-inactive"}`}
                style={{
                    position: "absolute", zIndex: "1000",
                    background: "#fff", borderRadius: "5px",
                    right: "18px", width: "300px"
                }}>
                <div className="modal-container-heading">
                    <p style={{
                        fontWeight: "650"
                    }}>Share Your Code</p>
                    <p>
                        <ClearRoundedIcon sx={{
                            fontSize: "25px", cursor: 'pointer', opacity: "0.6",
                            ":hover": { opacity: "1" }
                        }}
                            onClick={() => {
                                setIsOpen(false);
                                setIsClicked(false);
                            }}
                        />
                    </p>
                </div>
                <div className="modal-container-body">

                    <div className="share-link-window">
                        <LinkRoundedIcon />
                        <a href={process.env.REACT_APP_BASE_URL + "code/" + shareId}
                            style={{
                                overflow: "hidden",
                                textOverflow: "ellipsis"
                            }}
                            target="_blank" rel="noreferrer"
                        >
                            <div className="share-link">
                                {process.env.REACT_APP_BASE_URL + "code/" + shareId}
                            </div>
                        </a>
                    </div>

                    <div className="share-link-heading">
                        <label className="cpy-btn"
                            onClick={() => {
                                navigator.clipboard.writeText(process.env.REACT_APP_BASE_URL + "code/" + shareId);
                                showToastMessage("Link Copied!");
                            }}
                        >
                            <ContentCopyRoundedIcon sx={{ fontSize: "20px", justifyContent: "center" }} />
                            <span>Copy</span>
                        </label>

                    </div>
                </div>
            </div>
            {/* Confirmation Dialog */}
            <Dialog
                open={dialogOpen}
                onClose={handleDialogClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    {"Confirm Code Reset"}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        Are you sure you want to reset the code? This action cannot be undone.
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleDialogClose} color="primary">
                        Cancel
                    </Button>
                    <Button onClick={handleConfirmReset} color="primary" autoFocus>
                        Confirm
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
} 