import React, { useState, useEffect, useRef } from "react";
import getInitialCodes from "./InitialCodes";
import { NavLink } from "react-router-dom";

//icons:
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';

export default function LanguageDropdown({ dropdownHeading, setDropdownHeading,
    language, setLanguage, code, setCode
}) {
    const [open, setOpen] = useState(false);

    // Code to close dropdown on outside clicking of mouse:
    let menuRef = useRef();
    useEffect(() => {
        let handler = (e) => {
            if (menuRef.current && !menuRef.current.contains(e.target)) {
                setOpen(false);
            }
        };
        document.addEventListener("mousedown", handler);
        document.addEventListener('touchstart', handler);
        return () => {
            document.removeEventListener("mousedown", handler);
            document.removeEventListener('touchstart', handler);
        }
    });


    function handleClick(e) {
        setOpen(false);
        const lang = e.target.value;
        let langStr = "cpp";
        switch (lang) {
            case 1: langStr = "cpp"; break;
            case 2: langStr = "c"; break;
            case 3: langStr = "java"; break;
            case 4: langStr = "python"; break;
            case 5: langStr = "javascript"; break;
            default: langStr = "cpp"; break;
        }
        setLanguage(langStr);

        let headingText = "C++";
        switch (lang) {
            case 1: headingText = "C++"; break;
            case 2: headingText = "C"; break;
            case 3: headingText = "Java"; break;
            case 4: headingText = "Python"; break;
            case 5: headingText = "Javascript"; break;
            default: headingText = "C++"; break;
        }
        setDropdownHeading(headingText);

        setCode(getInitialCodes(langStr));
    }


    return (<>
        <div className="dropdown-menu-container">
            <div className="dropdown-trigger">
                <label ref={menuRef} style={{ margin: "0", cursor: "pointer" }} onClick={() => { setOpen(!open) }}>
                    <span>{getDropdownHeading(language)}</span>
                    <ArrowDropDownIcon sx={{ fontSize: "20px" }} />
                </label>
            </div>

            <div className={`dropdown-menu-items ${open ? "active" : "inactive"}`}>
                <ul>
                    <NavLink to="/online-cpp-compiler" style={{ textDecoration: "none" }}>
                        <DropdownItem value="1" handleClick={handleClick} text="C++" />
                    </NavLink>
                    <NavLink to="/online-c-compiler" style={{ textDecoration: "none" }}>
                        <DropdownItem value="2" handleClick={handleClick} text="C" />
                    </NavLink>

                    <NavLink to="/online-java-compiler" style={{ textDecoration: "none" }}>
                        <DropdownItem value="3" handleClick={handleClick} text="Java" />
                    </NavLink>

                    <NavLink to="/online-python-compiler" style={{ textDecoration: "none" }}>
                        <DropdownItem value="4" handleClick={handleClick} text="Python 3" />
                    </NavLink>

                    <NavLink to="/online-javascript-compiler" style={{ textDecoration: "none" }}>
                        <DropdownItem value="5" handleClick={handleClick} text="Javascript" />
                    </NavLink>

                </ul>
            </div>
        </div >
    </>);
}

function DropdownItem(props) {
    return (<>
        <li className="custom-dropdown-item" value={props.value} onClick={props.handleClick}>
            {props.text}
        </li>
    </>);
}

function getDropdownHeading(language) {
    switch (language) {
        case "cpp": return "C++";
        case "c": return "C";
        case "java": return "Java";
        case "python": return "Python";
        case "javascript": return "Javascript";
        default: return "C++";
    }
}