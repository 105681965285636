// import React from "react";

export default function getInitialCodes(lang) {
    const initialCodeCPP = `#include<bits/stdc++.h>
using namespace std;

int main(){
    //Write your code here. 
    cout << "Hello World!";
    return 0;
}`

    const initialCodeC = `#include <stdio.h>

int main() {
    printf("Hello world!");
    return 0;
}`

    const initialCodeJava = `import java.util.*;
import java.lang.*;
import java.io.*;

// The main method must be in a class named "Main"
class Main {
    public static void main(String[] args) {
        System.out.println("Hello world!");
    }
}`

    const initialCodePy = `print("Hello world!")`

    const initialCodeJs = `console.log("Hello world!");`

    switch (lang) {
        case 1: return initialCodeCPP;
        case 2: return initialCodeC;
        case 3: return initialCodeJava;
        case 4: return initialCodePy;
        case 5: return initialCodeJs;
        case "cpp": return initialCodeCPP;
        case "c": return initialCodeC;
        case "java": return initialCodeJava;
        case "python": return initialCodePy;
        case "javascript": return initialCodeJs;
        default: return initialCodeCPP;
    }
}
