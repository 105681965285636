import React from 'react'
import ReactDOM from 'react-dom';
import toast, { Toaster } from 'react-hot-toast';


//icons:
import ClearRoundedIcon from '@mui/icons-material/ClearRounded';
import RefreshRoundedIcon from '@mui/icons-material/RefreshRounded';

function SettingsPopup({ setShowSettings, theme, setTheme, ThemeOptions,
    fontFamily, setFontFamily, fontSize, setFontSize }) {

    const showToastMessage = (msg) => {
        toast.success(msg, {
            position: "top-right",
        });
    }

    const RowHeading = (props) => {
        return (
            <>
                <p>{props.text}</p>
            </>
        )
    }

    return ReactDOM.createPortal(
        <div className="modal-wrapper">
            <Toaster />
            <div className="modal-container">
                <div className="modal-container-heading">
                    <p>Editor Settings</p>
                    <p>
                        <ClearRoundedIcon sx={{
                            fontSize: "25px", cursor: 'pointer', opacity: "0.6",
                            ":hover": { opacity: "1" }
                        }}
                            onClick={() => { setShowSettings(false) }}
                        />
                    </p>
                </div>

                <div className="modal-container-body">

                    {/* ----------------------------Editor Theme Options */}
                    <div className="container-body-row">
                        <RowHeading text="Editor Theme Option" />
                        <EditorThemeDropdown theme={theme} setTheme={setTheme}
                            ThemeOptions={ThemeOptions}
                        />
                    </div>

                    {/* ----------------------------Editor Font Family Options */}
                    <div className="container-body-row">
                        <RowHeading text="Font Family" />
                        <FontFamilyDropdown
                            fontFamily={fontFamily} setFontFamily={setFontFamily}
                        />
                    </div>

                    {/* ----------------------------Editor Font Size Options */}
                    <div className="container-body-row">
                        <RowHeading text="Font Size" />
                        <FontSizeDropdown
                            fontSize={fontSize} setFontSize={setFontSize}
                        />
                    </div>
                </div>

                <div className="modal-container-footer">
                    <div className='modal-container-footer-btn'
                        onClick={() => {
                            setTheme(ThemeOptions[0]); setFontSize("16px");
                            setFontFamily("Ubuntu Mono");
                            showToastMessage("Settings reset to default.");
                        }}>
                        <p><RefreshRoundedIcon sx={{ fontSize: "20px" }} /></p>
                        <p className="ms-2">Reset</p>
                    </div>
                </div>
            </div>
        </div >,
        document.getElementById("modal-settings")
    );
}

const EditorThemeDropdown = ({ theme, setTheme, ThemeOptions }) => {
    return (
        <div className="container-row-dropdown">
            <div className="dropdown">
                <button className="btn btn-outline-primary dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                    {theme.text}
                </button>
                <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
                    <div className="dropdown-item" onClick={() => setTheme(ThemeOptions[0])}>
                        VS Dark
                    </div>
                    <div className="dropdown-item" onClick={() => setTheme(ThemeOptions[1])}>
                        VS Light
                    </div>
                    <div className="dropdown-item" onClick={() => setTheme(ThemeOptions[2])}>
                        High Contrast Dark
                    </div>
                </div>
            </div>
        </div>
    );
}

const FontFamilyDropdown = ({ fontFamily, setFontFamily }) => {
    return (
        <div className="container-row-dropdown">
            <div className="dropdown">
                <button className="btn btn-outline-primary dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                    {fontFamily}
                </button>
                <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
                    <div className="dropdown-item" onClick={() => setFontFamily("Anonymous Pro")}>
                        Anonymous Pro
                    </div>
                    <div className="dropdown-item" onClick={() => setFontFamily("Inconsolata")}>
                        Inconsolata
                    </div>
                    <div className="dropdown-item" onClick={() => setFontFamily("Source Code Pro")}>
                        Source Code Pro
                    </div>
                    <div className="dropdown-item" onClick={() => setFontFamily("Ubuntu Mono")}>
                        Ubuntu Mono
                    </div>
                </div>
            </div>
        </div>
    );
}

const FontSizeDropdown = ({ fontSize, setFontSize }) => {

    const Options = (props) => {
        return (
            <div className="dropdown-item" onClick={() => props.setFontSize(props.text)}>
                {props.text}
            </div>
        )
    }
    return (
        <div className="container-row-dropdown">
            <div className="dropdown">
                <button className="btn btn-outline-primary dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                    {fontSize}
                </button>
                <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
                    <Options text="12px" setFontSize={setFontSize} />
                    <Options text="14px" setFontSize={setFontSize} />
                    <Options text="16px" setFontSize={setFontSize} />
                    <Options text="18px" setFontSize={setFontSize} />
                    <Options text="20px" setFontSize={setFontSize} />
                    <Options text="22px" setFontSize={setFontSize} />
                    <Options text="24px" setFontSize={setFontSize} />
                    <Options text="26px" setFontSize={setFontSize} />
                    <Options text="28px" setFontSize={setFontSize} />
                    <Options text="30px" setFontSize={setFontSize} />
                </div>
            </div>
        </div>
    );
}

export default SettingsPopup;
