import React, { useState } from "react";
import LanguageDropdown from "./NavbarComponents/LanguageDropdown";
import FileDropdown from "./NavbarComponents/FileDropdown";
import TufLogo from "../images/logo.svg"

//icons:
import PlayCircleFilledRoundedIcon from '@mui/icons-material/PlayCircleFilledRounded';
import FavoriteIcon from '@mui/icons-material/Favorite';

function IdeHeader({ handleCompile, language, setLanguage, code, setCode, click }) {

    const [dropdownHeading, setDropdownHeading] = useState("C++");

    return (<>
        {/* -----------------------------------------------------------------------IDE header */}
        <div className="tuf-heading-container">
            <a className="tuf-heading-navbar" href="https://takeuforward.org/" target="_blank"
                rel="noreferrer" style={{ textDecoration: "none" }}>
                {/* <img className="tuf-logo" src={TufLogo} alt="TUF logo" /> */}
                <p className="tuf-heading">
                    takeUforward
                </p>
            </a>
        </div>
        {/* -----------------------------------------------------------------------IDE Navbar */}
        <div className="row navbar-container">
            <div className="navbar-options">

                {/* ---------------------------------------------------Language Dropdown section */}
                <LanguageDropdown
                    dropdownHeading={dropdownHeading} setDropdownHeading={setDropdownHeading}
                    language={language} setLanguage={setLanguage}
                    code={code} setCode={setCode}
                />

                {/* -----------------------------------------------------File dropdown section */}
                <FileDropdown code={code} setCode={setCode} language={language} />


                {/* ----------------------------------------------------Run button section-------- */}
                <div className={`run-btn ${click ? "" : "not-clickable"}`} onClick={handleCompile}>
                    <label style={{ margin: "0", cursor: "pointer" }}>
                        <PlayCircleFilledRoundedIcon sx={{ 
                            fontSize: "20px"
                            }} />
                        <span>{click ? "RUN" : "RUNNING"}</span>
                    </label>
                    
                </div>


            </div>

            {/* -----------------------------------------------------MADE WITH love by rtakeuforward */}
            <div className="navbar-right-heading">
                <span>MADE WITH</span>
                <FavoriteIcon sx={{ color: "red" }} />
                <span>BY</span>
                <a href="https://takeuforward.org/" target="_blank" rel="noreferrer"
                    style={{ all: "unset", cursor: "pointer" }}>
                    <img src={TufLogo} alt="TUF logo" />
                    <span className="navbar-right-tuf"
                        style={{ fontFamily: "Amaranth", fontSize: "18px", marginLeft: "2px" }}>
                        takeUforward
                    </span>
                </a>
            </div>

        </div >
    </>);
}

export default IdeHeader;