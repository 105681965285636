import React from "react";

import Editor from "@monaco-editor/react";

const CodeEditor = ({ code, language, theme, setCode, fontFamily, fontSize }) => {

    function handleEditorChange(value) {
        setCode(value);
    }

    return (
        <div>
            <Editor
                height="calc(100vh - 146px)"
                width="calc(100% - 2px)"
                language={language}
                value={code}
                theme={theme.theme}
                defaultValue="// TakeUForward Editor"
                options={{
                    fontSize: `${fontSize}`, fontFamily: `${fontFamily}`
                }}
                onChange={handleEditorChange}
            />
        </div>
    );
};
export default CodeEditor;