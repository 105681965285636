
export default function getFileName(lang) {
    switch (lang) {
        case "cpp": return "main.cpp";
        case "c": return "main.c";
        case "java": return "main.java";
        case "python": return "main.py";
        case "javascript": return "app.js";
        default: return "main.txt";
    }
}