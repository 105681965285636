import React from "react";
import toast, { Toaster } from 'react-hot-toast';
import "../App.css"

//icons:
import FileDownloadRoundedIcon from '@mui/icons-material/FileDownloadRounded';
import ContentCopyRoundedIcon from '@mui/icons-material/ContentCopyRounded';


function OutputWindow({ outputDetails, setOutputDetails, fontSize, fontFamily }) {

    //download output as txt file:
    const outputDownload = outputDetails ? outputDetails : "";
    const element = document.getElementById("output-download-btn");
    const outputFile = new Blob([outputDownload], { type: "text/plain" });
    if (element) element.href = URL.createObjectURL(outputFile);

    const showToastMessage = (msg) => {
        toast.success(msg, {
            position: "top-right",
        });
    }

    return (<>
        <div className="output-panel">
            <div className="panel-heading">
                <span className="panel-heading-text">Output</span>

                {/* ---------------------------------------Download Ouput Section------------------ */}
                <div className="panel-first-icon">
                    <a id="output-download-btn" download="output.txt" style={{ color: "#fff" }}
                        href={URL.createObjectURL(outputFile)}>
                        <span className="panel-heading-icon">
                            <FileDownloadRoundedIcon sx={{ fontSize: 20, ":hover": { color: "red" } }} />
                        </span>
                    </a>
                </div>

                {/* -----------------------------Copy output content section------------------------------ */}
                <div className="panel-second-icon">
                    <Toaster />
                    <span className="panel-heading-icon">
                        <ContentCopyRoundedIcon sx={{ fontSize: 20, ":hover": { color: "red" } }}
                            onClick={() => {
                                navigator.clipboard.writeText(outputDetails);
                                outputDetails && showToastMessage("Output copied successfully!")
                            }}
                        />
                    </span>
                </div>
            </div>

            {/* -----------------------------------------output display area----------------------------------- */}
            <div className="panel-outputbox">
                <textarea readOnly row="2" wrap="on" className="output-text"
                    value={outputDetails}
                    style={{
                        fontSize: `${fontSize}`
                    }} />
            </div>
        </div>
    </>);
}

export default OutputWindow;