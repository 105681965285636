import React from "react";
import toast, { Toaster } from 'react-hot-toast';
import "../App.css";

//icons:
import CloudUploadRoundedIcon from '@mui/icons-material/CloudUploadRounded';
import RefreshRoundedIcon from '@mui/icons-material/RefreshRounded';
import ContentCopyRoundedIcon from '@mui/icons-material/ContentCopyRounded';

function InputWindow({ customInput, setCustomInput, fontSize, fontFamily }) {

    //upload input file:
    const showFile = (e) => {
        if (window.File && window.FileReader && window.FileList && window.Blob) {
            var file = e.target.files[0];
            var reader = new FileReader()

            reader.onload = function (event) {
                setCustomInput(event.target.result);
            }
            reader.readAsText(file);

        } else {
            alert("Your browser is too old to support HTML5 File API");
        }
    }

    const showToastMessage = (msg) => {
        toast.success(msg, {
            position: "top-right",
        });
    }

    return (<>
        <div className="input-panel">
            <div className="panel-heading">
                <span className="panel-heading-text">Input</span>

                {/* -----------------------------------------Upload Input File------------------------------------ */}
                <div className="panel-first-icon">
                    <label className="panel-first-icon-label">
                        <span className="panel-heading-icon"><CloudUploadRoundedIcon sx={{ fontSize: "20px", ":hover": { color: "red" } }} /></span>
                        <input type="file" style={{ display: "none" }}
                            onChange={showFile}
                        />
                    </label>
                </div>

                {/* ------------------------------------Copy input section----------------------------------------------- */}
                <div className="panel-second-icon">
                    <Toaster />
                    <span className="panel-heading-icon"><ContentCopyRoundedIcon sx={{ fontSize: 20, ":hover": { color: "red" } }}
                        onClick={() => {
                            navigator.clipboard.writeText(customInput);
                            customInput && showToastMessage("Input copied successfully!")
                        }} /></span>
                </div>

                {/* -------------------------------------Clear Input section-------------------------------- */}
                <div className="panel-third-icon">
                    <span className="panel-heading-icon"><RefreshRoundedIcon sx={{ fontSize: 20, ":hover": { color: "red" } }}
                        onClick={() => setCustomInput("")}
                    /></span>
                </div>

            </div>

            {/* ----------------------------------------------input textbox------------------------------------- */}
            <textarea className="input-textbox"
                name="input" value={customInput} onChange={(e) => setCustomInput(e.target.value)}
                row="2" wrap="off" placeholder="Enter Input" spellCheck="false"
                style={{
                    fontSize: `${fontSize}`
                }}
            />
        </div >
    </>);
}

export default InputWindow;