const ThemeOptions = [
    {
        id: 1,
        text: "VS Dark",
        theme: "vs-dark",
    },
    {
        id: 2,
        text: "VS Light",
        theme: "light",
    },
    {
        id: 3,
        text: "High Contrast Dark",
        theme: "hc-black",
    }
];

export { ThemeOptions };